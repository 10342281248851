@import url(//fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(86, 210, 212);
  background: linear-gradient(121deg, rgba(86, 210, 212, 1) 0%, rgba(131, 186, 137, 1) 68%, rgba(208, 186, 75, 1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all .3s;
  background: rgb(86, 210, 212);
  background: linear-gradient(121deg, rgba(86, 210, 212, 1) 0%, rgba(131, 186, 137, 1) 68%, rgba(208, 186, 75, 1) 100%);
}


.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}
@media only screen and (max-width: 768px) {
.btn-toggle {
  display: flex;

}
}

.modal-header>button.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  /* border-radius: 0; */
  background-color: transparent;
  border: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

button.end-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.btn-add-project {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #222;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .editor-text {
  height: 450px !important;
  overflow-y: auto

} */

.ck.ck-content{
  
  height: 40vh;
  overflow-y: auto;
}

.ranking-number{
  font-weight: bolder;
  font-size: 16px;
}

.ranking-arrow>*{
  margin-top:-5px;
}

.down-rank{
  color:red
}

.unchange-rank{
  color:#ffc107
}

.up-rank{
  color:green
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 5px;
  height: 8px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
	background-color: #555555;
	border: 5px solid #555555;
}

.table-fixed{
  
  display: block;
  max-height: 400px;
  overflow-y: auto;
  font-size: 14px;
}

.table-fixed>thead{
  
  position:-webkit-sticky;
  
  position:sticky;
  top:0px;
  background-color: white;
  border-bottom: 1px solid #212529;
}


*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.block-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.block-wrap > div {
  width: 100%;
  text-align: center;
}

.btn-google, .btn-fb {
  display: inline-block;
  border-radius: 1px;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}
.btn-google .google-content, .btn-google .fb-content, .btn-fb .google-content, .btn-fb .fb-content {
  display: flex;
  align-items: center;
  width: 300px;
  height: 50px;
}
.btn-google .google-content .logo, .btn-google .fb-content .logo, .btn-fb .google-content .logo, .btn-fb .fb-content .logo {
  padding: 15px;
  height: inherit;
}
.btn-google .google-content svg, .btn-google .fb-content svg, .btn-fb .google-content svg, .btn-fb .fb-content svg {
  width: 18px;
  height: 18px;
}
.btn-google .google-content p, .btn-google .fb-content p, .btn-fb .google-content p, .btn-fb .fb-content p {
  width: 100%;
  line-height: 1;
  letter-spacing: 0.21px;
  text-align: center;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.btn-google {
  background: #FFF;
}
.btn-google:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}
.btn-google:active {
  background-color: #eee;
}
.btn-google .google-content p {
  color: #757575;
}

.btn-fb {
  padding-top: 1.5px;
  background: #4267b2;
  background-color: #3b5998;
}
.btn-fb:hover {
  box-shadow: 0 0 3px 3px rgba(59, 89, 152, 0.3);
}
.btn-fb .fb-content p {
  color: rgba(255, 255, 255, 0.87);
  margin:0
}

.text {
  margin-bottom:1.5rem;
  text-transform: uppercase;
  font-family: verdana;
  font-size: 3em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}
.note-item-wrapper {
    border-bottom: 1px solid #dadde1;
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: left;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.note-item-container {
    margin-top: 12px;
    text-align: left;
    direction: ltr;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    cursor: default;
    max-height: 50vh;
    overflow-y: auto;
}

.note-edit-wrapper>.item-action>button {
    max-width: 100%;
    letter-spacing: normal;
    color: #1c1e21;
    background-color: rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    font-weight: 400;
    font-family: Roboto, Arial, sans-serif;
    line-height: 30px;
    text-align: center;
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border-width: 0;
    margin: 5px;
}

.note-label-wrapper>textarea {
    background: #fff;
    border-color: #dadde1!important;
    color: #1c1e21;
    letter-spacing: normal;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 16px;
    max-height: none;
    height: 42px;
    border: 1px solid;
    border-radius: 2px;
    box-sizing: border-box;
    min-height: 35px;
    outline: none;
    overflow: auto;
    padding: 4px 6px;
    width: 100%;
    transition: all .15s ease 0s;
    margin: 0;
}

.note-label-wrapper, .note-label-wrapper-add {
    border: 0;
    width: 100%;
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #606770;
    cursor: default;
    font-weight: 600;
    vertical-align: middle;
    direction: ltr;
    text-align: left;
    overflow: hidden;
    position: relative;
    line-height: 1.34;
    margin: 0;
    padding: 0;
    unicode-bidi: embed;
}

.note-label-wrapper-add>textarea {
    background: #fff;
    border-color: #dadde1!important;
    color: #1c1e21;
    letter-spacing: normal;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 16px;
    max-height: none;
    resize: vertical;
    height: 42px;
    border: 1px solid;
    border-radius: 2px;
    box-sizing: border-box;
    min-height: 35px;
    outline: none;
    overflow: auto;
    padding: 4px 6px;
    width: 100%;
    transition: all .15s ease 0s;
    margin: 0;
}

.note-add-wrapper>.item-action {
    display: flex;
    justify-content: flex-end;
    direction: ltr;
    text-align: left;
    overflow: hidden;
    position: relative;
}

.note-add-wrapper>.item-action>button {
    max-width: 100%;
    letter-spacing: normal;
    color: #1c1e21;
    background-color: rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    font-weight: 400;
    font-family: Roboto, Arial, sans-serif;
    line-height: 30px;
    text-align: center;
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border-width: 0;
    margin: 5px;
}

.add-note-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.add-note-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.add-note-exit {
    opacity: 1;
}

.add-note-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}
