body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(86, 210, 212);
  background: linear-gradient(121deg, rgba(86, 210, 212, 1) 0%, rgba(131, 186, 137, 1) 68%, rgba(208, 186, 75, 1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all .3s;
  background: rgb(86, 210, 212);
  background: linear-gradient(121deg, rgba(86, 210, 212, 1) 0%, rgba(131, 186, 137, 1) 68%, rgba(208, 186, 75, 1) 100%);
}


.btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}
@media only screen and (max-width: 768px) {
.btn-toggle {
  display: flex;

}
}

.modal-header>button.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  /* border-radius: 0; */
  background-color: transparent;
  border: 0;
}