.note-item-wrapper {
    border-bottom: 1px solid #dadde1;
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: left;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.note-item-container {
    margin-top: 12px;
    text-align: left;
    direction: ltr;
    overflow: hidden;
    position: relative;
    font-size: 12px;
    cursor: default;
    max-height: 50vh;
    overflow-y: auto;
}

.note-edit-wrapper>.item-action>button {
    max-width: 100%;
    letter-spacing: normal;
    color: #1c1e21;
    background-color: rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    font-weight: 400;
    font-family: Roboto, Arial, sans-serif;
    line-height: 30px;
    text-align: center;
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border-width: 0;
    margin: 5px;
}

.note-label-wrapper>textarea {
    background: #fff;
    border-color: #dadde1!important;
    color: #1c1e21;
    letter-spacing: normal;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 16px;
    max-height: none;
    height: 42px;
    border: 1px solid;
    border-radius: 2px;
    box-sizing: border-box;
    min-height: 35px;
    outline: none;
    overflow: auto;
    padding: 4px 6px;
    width: 100%;
    transition: all .15s ease 0s;
    margin: 0;
}

.note-label-wrapper, .note-label-wrapper-add {
    border: 0;
    width: 100%;
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #606770;
    cursor: default;
    font-weight: 600;
    vertical-align: middle;
    direction: ltr;
    text-align: left;
    overflow: hidden;
    position: relative;
    line-height: 1.34;
    margin: 0;
    padding: 0;
    unicode-bidi: embed;
}

.note-label-wrapper-add>textarea {
    background: #fff;
    border-color: #dadde1!important;
    color: #1c1e21;
    letter-spacing: normal;
    font-size: 12px;
    font-family: Arial, sans-serif;
    line-height: 16px;
    max-height: none;
    resize: vertical;
    height: 42px;
    border: 1px solid;
    border-radius: 2px;
    box-sizing: border-box;
    min-height: 35px;
    outline: none;
    overflow: auto;
    padding: 4px 6px;
    width: 100%;
    transition: all .15s ease 0s;
    margin: 0;
}

.note-add-wrapper>.item-action {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    direction: ltr;
    text-align: left;
    overflow: hidden;
    position: relative;
}

.note-add-wrapper>.item-action>button {
    max-width: 100%;
    letter-spacing: normal;
    color: #1c1e21;
    background-color: rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    font-weight: 400;
    font-family: Roboto, Arial, sans-serif;
    line-height: 30px;
    text-align: center;
    height: 30px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 6px;
    border-width: 0;
    margin: 5px;
}

.add-note-enter {
    opacity: 0;
    transform: scale(0.9);
}

.add-note-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.add-note-exit {
    opacity: 1;
}

.add-note-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}