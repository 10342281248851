.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button.end-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.btn-add-project {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #222;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* .editor-text {
  height: 450px !important;
  overflow-y: auto

} */

.ck.ck-content{
  
  height: 40vh;
  overflow-y: auto;
}

.ranking-number{
  font-weight: bolder;
  font-size: 16px;
}

.ranking-arrow>*{
  margin-top:-5px;
}

.down-rank{
  color:red
}

.unchange-rank{
  color:#ffc107
}

.up-rank{
  color:green
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}
::-webkit-scrollbar
{
	width: 5px;
  height: 8px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
	background-color: #555555;
	border: 5px solid #555555;
}

.table-fixed{
  
  display: block;
  max-height: 400px;
  overflow-y: auto;
  font-size: 14px;
}

.table-fixed>thead{
  
  position:sticky;
  top:0px;
  background-color: white;
  border-bottom: 1px solid #212529;
}
