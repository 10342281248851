
@import url("//fonts.googleapis.com/css?family=Roboto");
*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
}

.block-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.block-wrap > div {
  width: 100%;
  text-align: center;
}

.btn-google, .btn-fb {
  display: inline-block;
  border-radius: 1px;
  text-decoration: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}
.btn-google .google-content, .btn-google .fb-content, .btn-fb .google-content, .btn-fb .fb-content {
  display: flex;
  align-items: center;
  width: 300px;
  height: 50px;
}
.btn-google .google-content .logo, .btn-google .fb-content .logo, .btn-fb .google-content .logo, .btn-fb .fb-content .logo {
  padding: 15px;
  height: inherit;
}
.btn-google .google-content svg, .btn-google .fb-content svg, .btn-fb .google-content svg, .btn-fb .fb-content svg {
  width: 18px;
  height: 18px;
}
.btn-google .google-content p, .btn-google .fb-content p, .btn-fb .google-content p, .btn-fb .fb-content p {
  width: 100%;
  line-height: 1;
  letter-spacing: 0.21px;
  text-align: center;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.btn-google {
  background: #FFF;
}
.btn-google:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}
.btn-google:active {
  background-color: #eee;
}
.btn-google .google-content p {
  color: #757575;
}

.btn-fb {
  padding-top: 1.5px;
  background: #4267b2;
  background-color: #3b5998;
}
.btn-fb:hover {
  box-shadow: 0 0 3px 3px rgba(59, 89, 152, 0.3);
}
.btn-fb .fb-content p {
  color: rgba(255, 255, 255, 0.87);
  margin:0
}

.text {
  margin-bottom:1.5rem;
  text-transform: uppercase;
  font-family: verdana;
  font-size: 3em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}